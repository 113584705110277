export enum FeatureFlag {
    GenericLink = "GenericLink",
    AirGap = "AirGap",
    V1GTrial = "V1GTrial",
    SimplifyOnboardingFlow = "SimplifyOnboardingFlow",
    Marketplace = "Marketplace",
}

export enum LoginFlag {
    I = "I",
    E = "E",
    M = "M",
}

export enum LoginFlowType {
    Foundation = "foundation",
    Poseidon = "poseidon",
}

export enum TokenType {
    Order = "order",
    Voucher = "voucher",
    Marketplace = "marketplace",
}

export enum ScenarioType {
    VisionOneOnly = "VisionOneOnly",
    CloudOneOnly = "CloudOneOnly",
    CloudOneAndVisionOne = "CloudOneAndVisionOne",
    GenericLink = "GenericLink",
    VoucherSimplifyOnboarding = "VoucherSimplifyOnboarding",
    OrderSimplifyOnboarding = "OrderSimplifyOnboarding",
    FoundationVisionOneOnly = "FoundationVisionOneOnly",
    FoundationCloudOneOnly = "FoundationCloudOneOnly",
    FoundationCloudOneAndVisionOne = "FoundationCloudOneAndVisionOne",
    FoundationGenericLink = "FoundationGenericLink",
    SMB = "SMB",
    Marketplace = "Marketplace",
    SmbPromotion = "SmbPromotion",
}

export enum ActivationPreviewTag {
    Preview_VisionOne = "Preview_VisionOne",
    Preview_CloudOne = "Preview_CloudOne",
    Preview_CloudOneWithVisionOne = "Preview_CloudOneWithVisionOne",
}

export enum PortalLink {
    GoogleChrome = "googleChrome",
    MozillaFirefox = "mozillaFirefox",
    MicrosoftEdge = "microsoftEdge",
    TrendMicroSupport = "trendMicroSupport",
    GetMoreInformation = "getMoreInformation",
    GlobalTrendMicroAgreement = "globalTrendMicroAgreement",
    JapanTrendMicroAgreement = "japanTrendMicroAgreement",
    DataCollectionNotice = "dataCollectionNotice",
    GlobalPrivacyNotice = "globalPrivacyNotice",
    JapanPrivacyNotice = "japanPrivacyNotice",
    TrendMicro = "trendMicro",
    LegalPoliciesPrivacy = "legalPoliciesPrivacy",
    ContactUs = "contactUs",
    Help = "help",
    CustomerLicensingPortal = "customerLicensingPortal",
    TrendMicroVisionOne = "trendMicroVisionOne",
    TrendMicroCloudOne = "trendMicroCloudOne",
    CreditConversionOnlineHelp = "creditConversionOnlineHelp",
    GenericLinkSupportProduct = "genericLinkSupportProduct",
    PostUpdateTaskLink = "postUpdateTaskLink",
}

export enum EntranceId {
    HasVisionOneEntrance = "hasVisionOneEntrance", // 1. Trend Vision One
    HasCloudOneEntrance = "hasCloudOneEntrance", // 2. Trend Cloud One
    HasCLPEntrance = "hasCLPEntrance", // 3. CLP
}

export enum ActivationType {
    ActivationCode = "ActivationCode",
    Account = "Account",
    CloudOne = "CloudOne",
}

export enum ImportFlowResult {
    RequireCreateUserAccount = "RequireCreateUserAccount",
    RequireConfirmPreview = "RequireConfirmPreview",
    OK = "OK",
}

export enum MarketplaceChannel {
    AWS = "AWS",
    GCP = "GCP",
    Azure = "Azure",
}
