import { getFrontendConfig } from "@/api/site";
import { IGetFrontendConfigResponse } from "@/types/api/site";
import { createContext, useEffect, useState } from "react";
import Loading from "@/components/common/Loading";

// default value
const defaultContext: IGetFrontendConfigResponse = {
    airgap: {
        importToolUrl: "",
        airgapEnvironment: "fedramp",
        accountFlow: "poseidon",
    },
    recaptcha: {
        siteKeyV2: "",
        siteKeyV3: "",
    },
    featureflags: {
        AirGap: false,
        GenericLink: false,
        Marketplace: false,
        SimplifyOnboardingFlow: false,
        V1GTrial: false,
    },
    frontendUrl: {
        clpDashboard: "",
        visionOnePortal: "",
        ssoLogin: "",
        foundationLanding: "",
        foundationFederation: "",
    },
};

export const frontendConfigContext = createContext<IGetFrontendConfigResponse>(
    defaultContext,
);

const FrontendConfig = ({ children }: { children: JSX.Element }) => {
    const [context, setContext] = useState(defaultContext);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        getFrontendConfig()
            .then((res) => {
                setContext((c) => ({
                    ...c,
                    ...res,
                }));
            })
            .finally(() => {
                setReady(true);
            });
    }, []);

    return ready ? (
        <frontendConfigContext.Provider value={context}>
            {children}
        </frontendConfigContext.Provider>
    ) : (
        <Loading />
    );
};

export default FrontendConfig;
