import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { isFirefox, isEdge, isChrome } from "@/utils/helper";
import ErrorPage from "../pages/ErrorPage";
import withLayout from "@/Hoc/withLayout";
import withCheckFeatureFlag from "@/Hoc/withCheckFeatureFlag";
import { FeatureFlag } from "@/types/enum";

// Code splitting
const Development = lazy(() => import("../pages/Development"));
const Activation = lazy(
    () => import(/* webpackChunkName: "Activation" */ "../pages/Activation"),
);
const Marketplace = lazy(
    () => import(/* webpackChunkName: "Marketplace" */ "../pages/Activation"),
);
const GenericLink = lazy(
    () => import(/* webpackChunkName: "GenericLink" */ "../pages/GenericLink"),
);
const OnBoarding = lazy(
    () => import(/* webpackChunkName: "CreateLink" */ "../pages/OnBoarding/CreateLink"),
);
const ExportFlow = lazy(
    () => import(/* webpackChunkName: "ExportPage" */ "../pages/ExportPage"),
);
const ImportFlow = lazy(
    () => import(/* webpackChunkName: "ImportPage" */ "../pages/ImportPage"),
);
const V1GTrial = lazy(
    () => import(/* webpackChunkName: "V1GTrial" */ "../pages/V1GTrial"),
);
const SmbPromotion = lazy(
    () => import(/* webpackChunkName: "SmbPromotion" */ "../pages/SmbPromotionPage"),
);
const NotSupport = lazy(
    () => import(/* webpackChunkName: "NotSupport" */ "../pages/NotSupport"),
);

// with layout/feature flag
const ActivationPage = withLayout(Activation);
const GenericLinkPage = withCheckFeatureFlag(
    withLayout(GenericLink),
    (features) => features[FeatureFlag.GenericLink],
);
const MarketplacePage = withCheckFeatureFlag(
    withLayout(Marketplace),
    (feature) => feature[FeatureFlag.Marketplace],
);
const OnBoardingPage = withCheckFeatureFlag(
    withLayout(OnBoarding, {
        footer: true,
        title: "Trend Vision One™️",
    }),
    (feature) => feature[FeatureFlag.SimplifyOnboardingFlow],
);
const OnBoardingDemoPage = withLayout(
    withCheckFeatureFlag(
        Activation,
        (feature) => feature[FeatureFlag.SimplifyOnboardingFlow],
    ),
    {
        footer: true,
        title: "Trend Vision One™️",
    },
);
const ErrorPageLayout = withLayout(ErrorPage);
const ExportPage = withLayout(
    withCheckFeatureFlag(ExportFlow, (feature) => feature.AirGap),
);
const AutoExportPage = withLayout(
    withCheckFeatureFlag(
        () => <ExportFlow autoExport />,
        (feature) => feature.AirGap,
    ),
);
const ImportPage = withLayout(
    withCheckFeatureFlag(ImportFlow, (feature) => feature.AirGap),
);
const AutoImportPage = withLayout(
    withCheckFeatureFlag(
        () => <ImportFlow autoImport />,
        (feature) => feature.AirGap,
    ),
);
const V1GTrialPage = withLayout(
    withCheckFeatureFlag(
        () => <V1GTrial />,
        (feature) => feature.AirGap && feature.V1GTrial,
    ),
);
const SmbPromotionPage = withLayout(SmbPromotion);
const NotSupportPage = withLayout(NotSupport, {
    footer: false,
});

// Route Group
const InvalidBrowserRoutes = () => (
    <Switch>
        <Route path="/not_support" component={NotSupportPage} />
        <Route path="*" render={() => <Redirect to="/not_support" />} />
    </Switch>
);

const dev =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ALLOW_ALL_FEATURE === "true";
const test = process.env.NODE_ENV === "test";
const GlobalRoutes = () => (
    <Switch>
        <Route path="/a1" component={ActivationPage} />
        <Route path="/g1" component={GenericLinkPage} />
        <Route path="/voucher" component={ActivationPage} />
        <Route path="/marketplace" component={MarketplacePage} />
        <Route path="/on-boarding/demo" exact component={OnBoardingDemoPage} />
        <Route path="/on-boarding" exact component={OnBoardingPage} />
        <Route path="/export" exact component={ExportPage} />
        <Route path="/export/auto" exact component={AutoExportPage} />
        <Route path="/v1g" exact component={V1GTrialPage} />
        <Route path="/smb/promotion" exact component={SmbPromotionPage} />
        {dev && <Route path="/import" exact component={ImportPage} />}
        {dev && <Route path="/import/auto" exact component={AutoImportPage} />}
        {/* <Redirect from="/a1/:token" to="/activation/:token" /> */}
        <Route
            path={[
                "/error/:code",
                "/error/:code?ticket=:ticket",
                "/error/?ticket=:ticket",
            ]}
            component={ErrorPageLayout}
        />
        <Route path="/not_support" component={NotSupportPage} />
        {dev || test ? (
            <Route path="/" component={withLayout(Development)} />
        ) : (
            <Redirect to="/error/EXA0000010" />
        )}
    </Switch>
);

const OfflineRoutes = () => (
    <Switch>
        <Route path="/import" exact component={ImportPage} />
        <Route path="/import/auto" exact component={AutoImportPage} />
        <Route
            path={[
                "/error/:code",
                "/error/:code?ticket=:ticket",
                "/error/?ticket=:ticket",
            ]}
            component={ErrorPageLayout}
        />
        <Route path="/not_support" component={NotSupportPage} />
        {process.env.NODE_ENV === "development" ? (
            <Route component={Development} />
        ) : (
            <Redirect to="/error/EXA0000010" />
        )}
    </Switch>
);

export default function Routes() {
    return (
        <Suspense fallback={null}>
            <Router basename={process.env.WEBPACK_PUBLIC_PATH}>
                {isFirefox || isEdge || isChrome ? (
                    process.env.REACT_APP_AIR_GAP === "Global" ? (
                        <GlobalRoutes />
                    ) : (
                        <OfflineRoutes />
                    )
                ) : (
                    <InvalidBrowserRoutes />
                )}
            </Router>
        </Suspense>
    );
}
